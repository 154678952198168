
.KetchwordBasic {
    display: grid;
    grid-template-columns: auto auto;
}

.KetchwordBasicPreview {
    

}

.ketchwords-main {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    width: 800px;
    margin-left: 10pt;
    line-height: 1.5;

    font-family: Arial, Verdana, Helvetica, sans-serif;

}

.ketchwords-logo {
    font-size: 0.6875em;  /* 11px */
    text-align: center;
    line-height: 0.6875em;
    font-family: Arial, Verdana, Helvetica, sans-serif;
}

.ketchwords-logo img {
    width: 100%;
}

.ketchwords-logo p {
    margin: 1em;
}

.ketchwords-logo a {
    color:rgb(171, 3, 3);
}

.ketchword {
    text-transform: uppercase;
}

.author-title {
    margin-bottom: 0;
    color: black;
}

.preview-content {
    border: 1px solid;
    background-color:white;
    display: flex;
    align-items: stretch;
    flex-direction: column;
}

.form-content {
    border: 1px solid;
    background-color: white;
    padding: 1em;
}

h2 {
    color: rgb(171, 3, 3);
    font-size: 1.2500em; /* 20px */
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-weight: bold;
}

h6 {
    text-align: left;
}

.campaign-form > h2, .ketchwords-main > h2 {
    background-color: #002D72;
    color: white;
    padding: 0.2em;
}


.footer {
    margin-top: 25pt;
    background-color: grey;
    color: white;
    font-size: .7500em; /* 12px; */
    line-height: 1;
}
.call-to-action {
    display: block;
    color: white;
    font-size: 1.1250em; /* 18px; */
    font-weight: bold;
    background-color: rgb(171, 3, 3);
    align-self: center;
    text-transform: uppercase;
    padding: 15px 30px 15px;
}

.call-to-action a {
    color: white;
    text-decoration: none;
}

.intro {
    text-align: left;
    padding: 10px 40px;
    margin: 0;
    font-size: 1.1250em; /* 18px; */
}

.bio {
    text-align: left;
    font-size: 1.1250em; /* 18px; */
    line-height: 1.5;
    padding: 10px 40px;
}

.spacer-thin {
    background-color: grey;
    height: 6pt;
    margin-top: 33px;
}

.title {
    color: rgb(171, 3, 3);
    font-size: 1.2500em; /* 20px; */
    text-align: center;
    margin: 0;
}

.title-prelude {
    align-self: flex-start;
    text-align: left;
    font-size: 1.1250em;
    padding: 10px 40px;
}


.thanks-banner {
    background-color: grey;
    color: white;
    font-size: 1.1250em; /* 18px; */
    padding: 10px 40px;
}
.thanks-banner p {
    font-weight: bold;
    margin: 0;
}

.thanks-banner .subline {
    font-size: .8750em; /* 14px; */
    font-weight: normal;
}


.your-logo {
    /*height: 10em;*/
    vertical-align: center;
    align-content: center;
    padding: 1em;
}

.your-logo img {
    max-width: 300px;
    max-height: 200px;
}

label.attachment {
    border: 1px solid lightgrey;
    padding: 0.3em;
    border-radius: 4px;
    text-align: left;
}